import React, { useEffect, useState } from "react";
import '../Component/ContactDetails.css';
import { Grid } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, IconButton, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FaEye } from "react-icons/fa";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap', // Prevent wrapping of text
    overflow: 'hidden', // Hide overflow text
    textOverflow: 'ellipsis', // Show ellipsis for overflow text
    maxWidth: 200, // Set maximum width for the table cell
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ContactDetails = (props) => {
  useEffect(() => {
    props.ViewallContact(props.login.admin_id);
  }, []);

  return (
    <>
      <Grid
        style={{
          height: "100vh",
          width: "calc(100vw - 180px)",
          margin: "30px 0 0 140px",
        }}
      >
        <h4 style={{ fontSize: "26px", marginTop: "60px" }}>Contacts</h4>
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700,marginBottom:"30px" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S. No</StyledTableCell>
                  {/* <StyledTableCell align="center">ID</StyledTableCell> */}
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Message</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(props.detailscontact.alldelatilscontact) &&
                  props.detailscontact.alldelatilscontact.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">{row._id}</StyledTableCell> */}
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.email}</StyledTableCell>
                      <StyledTableCell align="center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
  {row.message}
</StyledTableCell>

                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </>
  );
};
export default ContactDetails;
