const UNIVERSAL = {
  BASEURL: "https://superadmin.alphatechgateway.com/rest/api/",
  USERBASEURL: "http://34.93.117.25:4001/rest/api/",
  // ADMINBASEURL: "http://34.131.45.152:32806/rest/api/",
  ADMINUSERURL: "https://adminpanel.alphatechgateway.com/rest/api/",
  USERSERVICE: "https://bulkuser.alphatechgateway.com/rest/api/",
};





export default UNIVERSAL;
