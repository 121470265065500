import React, { Component } from "react";
import Router from "./router/containers/router_cont";
// import HotelBooking from "./pages/static/Accomodation/hotelbooking";


class App extends Component {
  render() {
    return (
      <div >
        <Router/>
      {/* <h1 style={styles.header}>500:Server Unreachable</h1>
      <p style={styles.message}>
        We are unable to connect to the server at the moment. Please try again later.
      </p> */}
    </div>
    );
  }
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
  },
  header: {
    fontSize: '2.5rem',
    color: '#ff0000',
  },
  message: {
    fontSize: '1.2rem',
    color: '#333',
  },
};
export default App;
